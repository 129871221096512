:root {
  --color-dark: #2e2e2e;
  --color-dark-2: #3d3d3d;
  --color-white: #e9e9e9;
  --color-white-2: #d1d1d1;
}

body {
  background-color: var(--color-white);
  margin: 0;
}

* {
  box-sizing: border-box;
  color: var(--color-dark) !important;
  border-color: var(--color-dark) !important;
  font-family: "Roboto Mono", monospace !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
