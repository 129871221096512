.MuiButton-root {
  text-transform: none !important;
}

.MuiDialog-paper {
  background-color: var(--color-white) !important;
}

.MuiLoadingButton-loading {
  color: transparent !important;
}
